<template>
     <div>
          <el-dialog lock-scroll="false" width="580px" top="15vh" v-model="dialogVisible" :before-close="dialogClose">
               <div>
                    <div class="title">{{ originPhone ? '修改手机号' : '绑定手机号' }}</div>
                    <div class="step_one" v-if="step == 1">

                         <table>

                              <template v-if="accountInfo.tlAccountState == 0 || accountInfo.tlAccountState == 12">
                                   <tr>
                                        <td colspan="2">
                                             <div class="tipN">
                                                  <div>暂不支持银行卡验证方式进行身份验证。因为您尚未完成实名认证。</div>
                                                  <div class="tip_go" @click="navTo('/member/auth')">立即认证 ></div>
                                             </div>
                                        </td>
                                   </tr>
                              </template>

                              <template v-else-if="!bankCards.length">
                                   <tr>
                                        <td colspan="2">
                                             <div class="tipN">
                                                  <div>尚未绑定银行卡，请绑定本人银行卡后操作</div>
                                                  <div class="tip_go" @click="navTo('/member/addCard')">立即绑定 >
                                                  </div>
                                             </div>
                                        </td>
                                   </tr>
                              </template>


                              <template v-else>
                                   <tr class="row">
                                        <td><span class="text1">姓名 :</span></td>
                                        <td class="flex_row_start_center"><span class="text1">{{
          accountInfo.personName
}}</span>
                                        </td>
                                   </tr>
                                   <tr class="row">
                                        <td><span class="text1">身份证号 :</span></td>
                                        <td class="flex_row_start_center">
                                             <span class="text1">{{ accountInfo.personIds }}</span>
                                        </td>
                                   </tr>
                                   <tr class="row">
                                        <td><span class="text1">银行卡号 :</span></td>
                                        <td class="flex_row_start_center">
                                             <input type="number" v-model="cardNo" class="long" placeholder="请输入本人银行卡号">
                                        </td>
                                   </tr>
                                   <tr class="row">
                                        <td><span class="text1">手机号 :</span></td>
                                        <td class="flex_row_start_center">
                                             <input type="number" v-model="cardPhone" class="long"
                                                  placeholder="请输入银行预留手机号">
                                        </td>
                                   </tr>
                                   <tr class="row">
                                        <td><span class="text1">验证码</span></td>
                                        <td class="flex_row_start_center">
                                             <input type="number" v-model="smsCodeBind">

                                             <div class="smsCode" @click="getSmsCode">
                                                  {{ countDownM ? `${countDownM}s` : '获取验证码' }}
                                             </div>
                                        </td>
                                   </tr>
                              </template>
                         </table>
                    </div>


                    <div class="step_one" v-if="step == 2">
                         <div class="flex_column_center_center success">
                              <img src="@/assets/success.png" alt="">
                              <p>{{ !originPhone ? '手机号码绑定成功' : '手机号码修改成功' }}</p>
                         </div>
                    </div>





                    <div class="bottom flex_row_end_center"
                         v-if="!(accountInfo.tlAccountState == 0 || accountInfo.tlAccountState == 12 || !bankCards.length)">
                         <div class="button_primary" @click="next">{{ step == 2 ? '完成' : '下一步' }}</div>
                    </div>
               </div>
          </el-dialog>
     </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, getCurrentInstance, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
export default {
     setup(props, { emit }) {
          const dialogVisible = ref(false)
          const process_on = require('@/assets/proccess_on.png')
          const process_off = require('@/assets/proccess_off.png')
          const chosen = require('@/assets/chosen.png')
          const chosen_yet = require('@/assets/chosen_yet.png')
          const smsCodeDis = ref('')
          const smsCodeBind = ref('')
          const countDownM = ref(0)
          let timeOutId = null
          const newPhone = ref('')
          const step = ref(1)
          const store = useStore()
          const memberInfo = store.state.memberInfo
          const originPhone = memberInfo.memberMobile
          const { proxy } = getCurrentInstance()
          const accountInfo = ref({})
          const bankCards = ref([])
          const router = useRouter()
          const cardNo = ref('')
          const cardPhone = ref('')
          const tranceNum = ref(0)
          const veryType = ref('phone')
          const changeType = (type) => {
               veryType.value = type
               if (timeOutId) {
                    clearTimeout(timeOutId)
                    timeOutId = null
                    countDownM.value = 0
               }
          }

          const next = () => {


               if (step.value == 1) {
                    if (!smsCodeBind.value) {
                         ElMessage.warning('请输入验证码')
                         return
                    }


                    if (!tranceNum.value) {
                         ElMessage.warning('请发送验证码')
                         return
                    }


                    proxy.$post('v3/member/front/memberPassword/bankChangeMobileConfirm', {
                         bankAccount: cardNo.value,
                         bankAccountPhone: cardPhone.value,
                         verificationCode: smsCodeBind.value,
                         isUnbound: 1,
                         tranceNum: tranceNum.value
                    }).then(res => {
                         if (res.state == 200) {
                              step.value = 2
                              memberInfo.memberMobile = cardPhone.value;
                              store.commit('updateMemberInfo', memberInfo)
                              clearTimeout(timeOutId)
                              timeOutId = null
                              countDownM.value = 0
                         }
                    })


               }


               if (step.value == 2) {
                    dialogVisible.value = false
                    emit('changeSuccess')
               }
          }

          const isPreSend = ref(false)
          const getSmsCode = () => {


               if (countDownM.value) {
                    return;
               }
               if (!cardNo.value) {
                    ElMessage.warning('请填写银行卡号')
                    return
               } if (cardNo.value.length > 19) {
                    ElMessage.warning('银行号输入超过个数限制')
                    return
               }

               if (!cardPhone.value) {
                    ElMessage.warning('请填写手机号')
                    return
               } else if (proxy.$checkMobile(cardPhone.value) != true) {
                    ElMessage.warning(proxy.$checkMobile(cardPhone.value))
                    return
               }

               if (isPreSend.value) {
                    return
               }
               isPreSend.value = true



               proxy.$post('v3/member/front/memberPassword/bankChangeMobileApply', {
                    bankAccount: cardNo.value,
                    bankAccountPhone: cardPhone.value
               }).then(res => {
                    if (res.state == 200) {
                         tranceNum.value = res.data
                         countDownM.value = 60;
                         countDown();
                    } else if (res.state == 267) {
                         proxy.$confirm(res.msg, '提示', {
                              confirmButtonText: '确定',
                              cancelButtonText: '取消',
                              type: 'warning'
                         }).then(() => {
                              tranceNum.value = res.data
                              countDownM.value = 60;
                              countDown();
                         }).catch(() => {
                              isPreSend.value = false
                         })
                    } else {
                         isPreSend.value = false
                         ElMessage.error(res.msg)
                    }
               })
          }


          //倒计时
          const countDown = () => {
               countDownM.value--;
               if (countDownM.value == 0) {
                    isPreSend.value = false
                    clearTimeout(timeOutId);
               } else {
                    timeOutId = setTimeout(countDown, 1000);
               }
          }


          const varifyAccount = () => {
               proxy.$get('v3/member/front/openAccount/detail').then(res => {
                    if (res.state == 200) {
                         accountInfo.value = res.data
                    }
               })
          }


          const getBankCard = () => {
               proxy.$get('v3/member/front/bankAccount/list').then(res => {
                    if (res.state == 200) {
                         bankCards.value = res.data.list
                    }
               })
          }


          const navTo = (url) => {
               router.push(url)
          }


          const dialogClose = (done) => {
               if (step.value == 3) {
                    emit('changeSuccess')
                    done()
               } else {
                    done()
               }
          }


          onMounted(() => {
               varifyAccount()
               getBankCard()
          })

          return {
               dialogVisible,
               process_on,
               process_off,
               chosen,
               chosen_yet,
               step,
               smsCodeBind,
               smsCodeDis,
               newPhone,
               originPhone,
               veryType,
               changeType,
               countDownM,
               getSmsCode,
               accountInfo,
               bankCards,
               navTo,
               getBankCard,
               cardNo,
               cardPhone,
               next,
               dialogClose,
               memberInfo
          }
     },

     directives: {
          backImg: {
               mounted(el, bindings, vnode, preVnode) {
                    // console.log(el, bindings, vnode, preVnode, "focus mounted");
                    el.style.backgroundImage = `url(${bindings.value})`
                    el.style.backgroundSize = 'contain'
                    el.style.backgroundPosition = 'center center'
                    el.style.backgroundRepeat = 'no-repeat'
               },

               updated(el, bindings, vnode, preVnode) {
                    // console.log(el, bindings, vnode, preVnode)
                    el.style.backgroundImage = `url(${bindings.value})`
               }
          }
     }

}
</script>

<style lang="scss">
.title {
     font-size: 20px;
     font-family: Microsoft YaHei;
     font-weight: bold;
     color: #222222;
     text-align: center;
     margin-bottom: 30px;
}

.el-dialog__headerbtn,
.el-dialog__close:hover {
     color: $colorMain !important;
}

.bottom {
     margin-top: 40px;

     .button_primary {
          width: 100px;
          height: 40px;
          background: #E2231A;
          border-radius: 3px;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
     }
}

.process_bar {
     /* height: 100rpx; */
     width: 100%;
     /* background: #F5F5F5; */


     .el-dialog__header {
          display: none;
     }






     .bar_item {

          img,
          .img {
               width: 37px;
               height: 37px;
               text-align: center;
               line-height: 37px;
               color: #fff;
          }

          span {
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #B7B7B7;
               margin-top: 5px;

               &.over {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #F7452D;
               }
          }
     }

     .line {
          width: 110px;
          height: 1px;
          background: #DCD8D8;
          margin: 0 20px;
     }
}


.step_one {
     margin-top: 40px;
     padding: 0 20px;
     padding-bottom: 20px;

     table {
          border-spacing: 20px;

          .row {
               font-size: 16px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #333333;


               .text1 {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
               }

               img {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
               }

               .long {
                    width: 350px;
               }

               input {
                    width: 240px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #C9C9C9;
                    border-radius: 3px;
                    padding: 0 10px;
                    font-size: 14px;
               }

               input::-webkit-outer-spin-button,
               input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
               }

               input[type="number"] {
                    -moz-appearance: textfield;
               }

               td {
                    text-align: right;
               }

               .smsCode {
                    width: 100px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #C9C9C9;
                    border-radius: 3px;
                    margin-left: 12px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    cursor: pointer;
               }
          }
     }



     .success {
          img {
               width: 71px;
               height: 71px
          }

          p {
               margin-top: 20px;
               font-size: 16px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #333333;
          }

     }
}

.tipN {
     font-size: 16px;
     font-family: Microsoft YaHei;
     font-weight: 400;
     color: #333333;

     .tip_go {
          margin-top: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #E2231A;
          cursor: pointer;

          &:hover {
               text-decoration: underline;
          }
     }
}
</style>